import React from "react";
import { MinisteringCompanionship } from "./MinisteringCompanionship";

export const MinisteringDistrict = ({ unitDetails, membersList, district, companionships, companions, assignments, createMinisteringAssignment, createMinisteringCompanion, updateMinisteringAssignment, updateMinisteringCompanion, deleteMinisteringAssignment, deleteMinisteringCompanion }) => {
  return (
    <div className="organization-section">
      <h2
        style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "8px", position: 'relative' }}
      >
        {district.type} {district.name}
      </h2>
      {
        companionships.filter(row => row.ministering_district_id === district.id).map((companionship, index) => (
          <MinisteringCompanionship
            key={index}
            unitDetails={unitDetails}
            membersList={membersList}
            companionshipId={companionship.id}
            type={district.type}
            allCompanions={companions}
            allAssignments={assignments}
            companions={companions.filter(row => row.ministering_companionship_id === companionship.id)}
            assignments={assignments.filter(row => row.ministering_companionship_id === companionship.id)}
            createMinisteringAssignment={createMinisteringAssignment}
            createMinisteringCompanion={createMinisteringCompanion}
            updateMinisteringAssignment={updateMinisteringAssignment}
            updateMinisteringCompanion={updateMinisteringCompanion}
            deleteMinisteringAssignment={deleteMinisteringAssignment}
            deleteMinisteringCompanion={deleteMinisteringCompanion}
          />
        ))
      }
    </div>
  );
};
