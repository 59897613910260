import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling
import './tooltip.css'; // Custom tooltip styling

// https://simplicable.com/colors/earth-tone
export const MyOrganizationLegend = ({ unitDetails }) => {
  return (
    <div className="legend">
      <div className="legend-controls">
        <div></div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              className="bx bx-square-rounded"
              style={{
                marginRight: "4px",
                fontSize: "18px",
                borderRadius: "4px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#90d6e1",
                backgroundColor: "#90d6e1",
              }}
            />
            <strong>
              {unitDetails.language === "es" ? "Llamamiento abierto" : "Placeholder"}
              <Tippy content={unitDetails.language === "es" ? "Llamamientos abiertos que necesitan una recomendación" : "Open callings needing a recommendation"} theme="lcrplus">
                <i className="bx bx-info-circle" style={{ marginLeft: '5px', fontSize: "14px" }}></i>
              </Tippy>
            </strong>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              className="bx bx-square-rounded"
              style={{
                marginRight: "4px",
                fontSize: "18px",
                borderRadius: "4px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#029bb2",
                backgroundColor: "#029bb2",
              }}
            />
            <strong>
              {unitDetails.language === "es" ? "Llamamiento en progreso" : "Pending calling"}
              <Tippy content={unitDetails.language === "es" ? "Recomendaciones de llamamientos que necesitan ser aprobadas, extendidas, sostenidas o apartadas" : "Calling recommendations that need to be approved, extended, sustained, or set apart"} theme="lcrplus">
                <i className="bx bx-info-circle" style={{ marginLeft: '5px', fontSize: "14px" }}></i>
              </Tippy>
            </strong>
          </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              marginRight: "4px",
              fontSize: "18px",
              borderRadius: "4px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#d988bb",
              backgroundColor: "#d988bb",
            }}
          />
          <strong>{unitDetails.language === "es" ? "6-12 meses en el llamamiento" : unitDetails.shorter_tenure_label}</strong>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              marginRight: "4px",
              fontSize: "18px",
              borderRadius: "4px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#a33b7d",
              backgroundColor: "#a33b7d",
            }}
          />
          <strong>{unitDetails.language === "es" ? "12+ meses en el llamamiento" : unitDetails.longer_tenure_label}</strong>
        </div>
        <div></div>
      </div>
    </div>
  );
};