import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";
import { MemberDetails } from "./MemberDetails";

export const MinisteringCard = ({ member, updateMinisteringAssignment, updateMinisteringCompanion, deleteMinisteringAssignment, deleteMinisteringCompanion }) => {
  const zoomedOut = true;
  const [showHouseholdPhoto, setShowHouseholdPhoto] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [isPendingUnassignment, setIsPendingUnassignment] = useState(member.is_pending_unassignment);

  // Function to toggle between member and household photo
  const togglePhoto = () => {
    setShowHouseholdPhoto(!showHouseholdPhoto);
  };

  // Prepare photo sources
  let memberPhotoSrc = member.member_photo;
  let householdPhotoSrc = member.household_photo;

  // Determine which photo to display
  let currentPhotoSrc;
  let isDefaultPicture = false;
  if (memberPhotoSrc && householdPhotoSrc) {
    currentPhotoSrc = showHouseholdPhoto ? householdPhotoSrc : memberPhotoSrc;
  } else if (memberPhotoSrc) {
    currentPhotoSrc = memberPhotoSrc;
  } else if (householdPhotoSrc) {
    currentPhotoSrc = householdPhotoSrc;
  } else {
    currentPhotoSrc = "/no_profile_picture.jpeg";
    isDefaultPicture = true;
  }

  const name = member.name.split(",").map((part) => part.trim()).reverse().join(" ");
  let borderColor = "#b0b0b0";
  if (member.is_auto_generated) {
    borderColor = "#029bb2"
  } else if (isPendingUnassignment) {
    borderColor = "#ea4335"
  }

  const handleUpdateIsPendingUnassignment = () => {
    setIsPendingUnassignment(!isPendingUnassignment);
    if (member.ministering_assignment_id) {
      updateMinisteringAssignment(member.ministering_assignment_id, !isPendingUnassignment);
    } else if (member.ministering_companion_id) {
      updateMinisteringCompanion(member.ministering_companion_id, !isPendingUnassignment);
    }
  };

  const handleDelete = () => {
    if (member.ministering_assignment_id) {
      deleteMinisteringAssignment(member.ministering_assignment_id);
    } else if (member.ministering_companion_id) {
      deleteMinisteringCompanion(member.ministering_companion_id);
    }
  }

  if (zoomedOut) {
    return (
      <>
        <div
          className="member-card"
          style={{
            border: `3px solid ${borderColor}`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <DropdownMenu>
            <DropdownMenuTrigger style={{cursor: "pointer", position: "absolute", top: "0px", right: "-5px", zIndex: 1000}} asChild>
              <i className="bx bx-dots-vertical-rounded" style={{ fontSize: "24px" }}></i>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="dropdown-content"
              align="end"
              side="bottom"      
              style={{zIndex: 1000}}
            >
              <DropdownMenuRadioGroup>
                {!isPendingUnassignment && !member.is_auto_generated && (
                  <DropdownMenuRadioItem
                    className="dropdown-item"
                    onClick={handleUpdateIsPendingUnassignment}
                    style={{ fontSize: "18px" }}
                  >
                    <i className="bx bx-user-minus" style={{ marginRight: "5px", fontSize: "16px" }}></i>
                    Unassign
                  </DropdownMenuRadioItem>
                )}
                {isPendingUnassignment && !member.is_auto_generated && (
                  <DropdownMenuRadioItem
                    className="dropdown-item"
                    onClick={handleUpdateIsPendingUnassignment}
                    style={{ fontSize: "18px" }}
                  >
                    <i className="bx bx-undo" style={{ marginRight: "5px", fontSize: "16px" }}></i>
                    Clear unassigned status
                  </DropdownMenuRadioItem>
                )}
                {member.is_auto_generated && (
                  <DropdownMenuRadioItem
                    className="dropdown-item"
                    onClick={handleDelete}
                    style={{ fontSize: "18px" }}
                  >
                    <i className="bx bx-trash" style={{ marginRight: "5px", fontSize: "16px" }}></i>
                    Delete
                  </DropdownMenuRadioItem>
                )}
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
          <div
            style={{
              height: "125px",
              width: "125px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            <img
              src={currentPhotoSrc}
              alt={name}
              className="member-photo"
              style={isDefaultPicture ? { height: "75px", width: "75px" } : {}}
            />
          </div>
          <div
            style={{
              fontSize: "15px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "140px",
              marginTop: "17.5px",
              cursor: "pointer"
            }}
            onClick={() => setShowDetails(true)}
          >
            <strong>{name}</strong>
          </div>
        </div>
        {showDetails && <MemberDetails member={member} setShowDetails={setShowDetails} />}
      </>
    );
  }

  return (
    <>
      <div
        className="member-card"
        style={{
          border: `5px solid ${borderColor}`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <DropdownMenu>
          <DropdownMenuTrigger style={{cursor: "pointer", position: "absolute", top: "0px", right: "-5px", zIndex: 1000}} asChild>
            <i className="bx bx-dots-vertical-rounded" style={{ fontSize: "24px" }}></i>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="dropdown-content"
            align="end"
            side="bottom"      
            style={{zIndex: 1000}}
          >
            <DropdownMenuRadioGroup>
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <div
          style={{
            position: "relative",
            width: "240px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {memberPhotoSrc && householdPhotoSrc && !showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-right"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                right: "-15px",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
          <div
            style={{
              height: "210px",
              width: "210px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            <img
              src={currentPhotoSrc}
              alt={name}
              className="member-photo"
              style={isDefaultPicture ? { height: "100px", width: "100px" } : {}}
            />
          </div>
          {memberPhotoSrc && householdPhotoSrc && showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-left"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                left: "-15px",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
        </div>
        <div
          style={{
            fontSize: "18px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "240px",
            marginTop: "36px",
            cursor: "pointer",
          }}
          onClick={() => setShowDetails(true)}
        >
          <strong>{name}</strong>
        </div>
      </div>
      {showDetails && <MemberDetails member={member} setShowDetails={setShowDetails} />}
    </>
  );
};