import React from "react";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './tooltip.css';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";
import * as Tabs from '@radix-ui/react-tabs';
import { Logo } from "./Logo";

export const Header = ({
  activeTab,
  overallNumbers,
  handleTabSelection,
  zoomedOut,
  setZoomedOut,
  unitDetails,
  sessionInfo,
  autoGenerateMinistering,
  resetMinistering,
}) => {
  return (
    <div className="header">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {sessionInfo.accessLevel != null &&
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div style={{ cursor: "pointer", marginTop: "2px", marginRight: "10px", marginLeft: "10px" }}>
                <i className="bx bx-menu" style={{ padding: "2px", fontSize: "26px", borderRadius: "4px", boxShadow: "0 -2px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1)" }}></i>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="dropdown-content"
              style={{ zIndex: 3000 }}
            >
              <DropdownMenuRadioGroup value={activeTab} onValueChange={handleTabSelection}>
                {sessionInfo.accessLevel === "BISHOPRIC" &&
                  <DropdownMenuRadioItem
                    value="home"
                    className={`dropdown-item ${activeTab === "home" ? "selected" : ""}`}
                  >
                    <i className="bx bx-home" style={{ marginRight: '5px', fontSize: "18px", position: 'relative', top: '2px' }}></i>
                    {unitDetails.language === "es" ? "Inicio" : "Home"}
                  </DropdownMenuRadioItem>
                }
                {sessionInfo.accessLevel === "PRESIDENCY" &&
                  <DropdownMenuRadioItem
                    value="myOrganization"
                    className={`dropdown-item ${activeTab === "myOrganization" ? "selected" : ""}`}
                  >
                    <i className="bx bx-home" style={{ marginRight: '5px', fontSize: "18px", position: 'relative', top: '2px' }}></i>
                    {unitDetails.language === "es" ? "Mi organización" : "My organization"}
                  </DropdownMenuRadioItem>
                }
                <DropdownMenuRadioItem
                  value="callingsByOrganization"
                  className={`dropdown-item ${activeTab === "callingsByOrganization" ? "selected" : ""}`}
                >
                  <i className="bx bx-sitemap" style={{ marginRight: '5px', fontSize: "18px", position: 'relative', top: '2px' }}></i>
                  {unitDetails.language === "es" ? "Llamamientos por organización" : "Callings by organization"}
                </DropdownMenuRadioItem>
                {sessionInfo.accessLevel === "BISHOPRIC" &&
                  <>
                    <DropdownMenuRadioItem
                      value="membersWithoutCallings"
                      className={`dropdown-item ${activeTab === "membersWithoutCallings" ? "selected" : ""}`}
                    >
                      <i className="bx bx-group" style={{ marginRight: '5px', fontSize: "18px", position: 'relative', top: '2px' }}></i>
                      {unitDetails.language === "es" ? "Miembros sin llamamientos" : "Members without callings"}
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem
                      value="prospectiveElders"
                      className={`dropdown-item ${activeTab === "prospectiveElders" ? "selected" : ""}`}
                    >
                      <i className="bx bx-badge-check" style={{ marginRight: '5px', fontSize: "18px", position: 'relative', top: '2px' }}></i>
                      {unitDetails.language === "es" ? "Élderes en perspectiva" : "Prospective Elders"}
                    </DropdownMenuRadioItem>
                  </>
                }
                {["BISHOPRIC", "COMMUNICATION"].includes(sessionInfo.accessLevel) && unitDetails.twilio_phone_number &&
                  <DropdownMenuRadioItem
                    value="communication"
                    className={`dropdown-item ${activeTab === "communication" ? "selected" : ""}`}
                  >
                    <i className="bx bx-message" style={{ marginRight: '5px', fontSize: "18px", position: 'relative', top: '2px' }}></i>
                    {unitDetails.language === "es" ? "Comunicación" : "Communication"}
                  </DropdownMenuRadioItem>
                }
                {["BISHOPRIC", "PRESIDENCY"].includes(sessionInfo.accessLevel) && sessionInfo.canAccessNotes && unitDetails.id !== 1 &&
                  <DropdownMenuRadioItem
                    value="ministering"
                    className={`dropdown-item ${activeTab === "ministering" ? "selected" : ""}`}
                    style={{ backgroundColor: "#f5f5f5", color: "#a0a0a0", cursor: "default" }}
                    disabled
                  >
                    <i className="bx bx-donate-heart" style={{ marginRight: '5px', fontSize: "18px", position: 'relative', top: '2px' }}></i>
                    {unitDetails.language === "es" ? "Ministerio" : "Ministering"}
                    <span style={{ marginLeft: "22px", fontSize: "12px", backgroundColor: "#abf2b8", borderRadius: "4px", border: "2px", padding: "1px" }}>
                      {unitDetails.language === "es" ? "PRÓXIMAMENTE" : "COMING SOON"}
                    </span>
                  </DropdownMenuRadioItem>
                }
                {((sessionInfo.accessLevel === "PRESIDENCY" && sessionInfo.gender === "F") || sessionInfo.accessLevel === "BISHOPRIC") && sessionInfo.canAccessNotes && unitDetails.id === 1 &&
                  <DropdownMenuRadioItem
                    value="ministering"
                    className={`dropdown-item ${activeTab === "ministering" ? "selected" : ""}`}
                  >
                    <i className="bx bx-donate-heart" style={{ marginRight: '5px', fontSize: "18px", position: 'relative', top: '2px' }}></i>
                    {unitDetails.language === "es" ? "Ministerio" : "Ministering"}
                    <span style={{ marginLeft: "55px", fontSize: "12px", color: "#808080", backgroundColor: "#90d6e1", borderRadius: "4px", border: "2px", paddingTop: "1px", paddingBottom: "1px", paddingLeft: "3px", paddingRight: "3px" }}>
                      BETA
                    </span>
                  </DropdownMenuRadioItem>
                }
                {/* {sessionInfo.accessLevel === "BISHOPRIC" &&
                  <DropdownMenuRadioItem
                    value="covenantPath"
                    className={`dropdown-item ${activeTab === "covenantPath" ? "selected" : ""}`}
                    style={{ backgroundColor: "#f5f5f5", color: "#a0a0a0", cursor: "default" }}
                    disabled
                  >
                    <i className="bx bxs-direction-right" style={{ marginRight: '5px', fontSize: "18px", position: 'relative', top: '2px' }}></i>
                    {unitDetails.language === "es" ? "Sendero de convenios" : "Covenant path"}
                    <span style={{ marginLeft: "22px", fontSize: "12px", backgroundColor: "#abf2b8", borderRadius: "4px", border: "2px", padding: "1px" }}>
                      {unitDetails.language === "es" ? "PRÓXIMAMENTE" : "COMING SOON"}
                    </span>
                  </DropdownMenuRadioItem>
                } */}
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        }
        {/*<div style={{ marginTop: "2px", paddingLeft: "15px" }}><Logo /></div>*/}
        {activeTab === "home" && (<h1 className="welcome">{unitDetails.language === "es" ? "Tablero" : "Dashboard"}</h1>)}
        {activeTab === "myOrganization" && (<h1 className="welcome">{unitDetails.language === "es" ? "Mi organización" : "My organization"}</h1>)}
        {activeTab === "callingsByOrganization" && (<h1 className="welcome">{unitDetails.language === "es" ? "Llamamientos por organización" : "Callings by organization"}</h1>)}
        {activeTab === "membersWithoutCallings" && (<h1 className="welcome">{unitDetails.language === "es" ? "Miembros sin llamamientos" : "Members without callings"}</h1>)}
        {activeTab === "prospectiveElders" && (<h1 className="welcome">{unitDetails.language === "es" ? "Élderes en perspectiva" : "Prospective Elders"}</h1>)}
        {activeTab === "communication" && (<h1 className="welcome">{unitDetails.language === "es" ? "Comunicación" : "Communication"}</h1>)}
        {activeTab === "ministering" && (<h1 className="welcome">{unitDetails.language === "es" ? "Ministerio" : "Ministering"}</h1>)}
      </div>
      {overallNumbers && (
        <div className="overall-container">
          {activeTab === "callingsByOrganization" && (
            <>
              <div className="overall-box">
                <div className="overall-label">{unitDetails.language === "es" ? "MIEMBROS CON LLAMAMIENTOS" : "MEMBERS WITH CALLINGS"}</div>
                <div className="overall-number">{overallNumbers["members_with_callings_count"]}</div>
              </div>
              <div className="overall-box">
                <div className="overall-label">
                  {unitDetails.language === "es" ? "MIEMBROS TOTALES" : "TOTAL MEMBERS"}
                </div>
                <div className="overall-number">{overallNumbers["total_members_count"]}</div>
              </div>
              <div className="overall-box">
                <div className="overall-label">{unitDetails.language === "es" ? "% CON LLAMAMIENTOS" : "PERCENT WITH CALLINGS"}</div>
                <div className="overall-number">{(overallNumbers["members_with_callings_count"] / overallNumbers["total_members_count"] * 100).toFixed()}%</div>
              </div>
            </>
          )}
          {activeTab === "membersWithoutCallings" && (
            <>
              <div className="overall-box">
                <div className="overall-label">{unitDetails.language === "es" ? "MIEMBROS SIN LLAMAMIENTOS" : "MEMBERS WITHOUT CALLINGS"}</div>
                <div className="overall-number">{overallNumbers["members_without_callings_count"]}</div>
              </div>
              <div className="overall-box">
                <div className="overall-label">
                  {unitDetails.language === "es" ? "MIEMBROS TOTALES" : "TOTAL MEMBERS"}
                </div>
                <div className="overall-number">{overallNumbers["total_members_count"]}</div>
              </div>
              <div className="overall-box">
                <div className="overall-label">{unitDetails.language === "es" ? "% SIN LLAMAMIENTOS" : "PERCENT WITHOUT CALLING"}</div>
                <div className="overall-number">{(overallNumbers["members_without_callings_count"] / overallNumbers["total_members_count"] * 100).toFixed()}%</div>
              </div>
            </>
          )}
          {sessionInfo.accessLevel === "PRESIDENCY" && sessionInfo.canAccessNotes && activeTab === "ministering" && (
            <>
              <button onClick={() => autoGenerateMinistering()} className="cta">
                {unitDetails.language === "es" ? "Autogenerar" : "Auto-generate"}
                <i style={{ marginLeft: '5px', fontSize: '18px', position: 'relative', top: '3px' }} className="bx bxs-bolt"></i>
              </button>
              <button onClick={() => resetMinistering()} className="cta">
                {unitDetails.language === "es" ? "Restablecer" : "Reset"}
                <i style={{ marginLeft: '5px', fontSize: '18px', position: 'relative' }} className="bx bx-reset"></i>
              </button>
            </>
          )}
          {sessionInfo.accessLevel === "BISHOPRIC" && ["home", "callingsByOrganization", "membersWithoutCallings"].includes(activeTab) && (
            <Tabs.Root defaultValue={zoomedOut} style={{ display: 'flex', gap: '8px' }}>
              <Tabs.List style={{ display: 'flex', backgroundColor: "#d0d0d0", borderRadius: '6px', height: "32px" }}>
                <Tabs.Trigger
                  value={true}
                  onClick={() => setZoomedOut(true)}
                  style={{
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    border: 'none',
                    borderRadius: '4px',
                    color: 'black',
                    backgroundColor: zoomedOut ? 'white' : '#d0d0d0',
                    cursor: 'pointer',
                    margin: '2px',
                    transition: 'background-color 0.2s',
                  }}
                >
                  <strong>{unitDetails.language === "es" ? "Ver" : "View"}</strong>
                  <i style={{ marginLeft: '5px', fontSize: '18px', position: 'relative', top: '3px' }} className="bx bx-show"></i>
                </Tabs.Trigger>
                <Tabs.Trigger
                  value={false}
                  onClick={() => setZoomedOut(false)}
                  style={{
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    border: 'none',
                    borderRadius: '4px',
                    color: 'black',
                    backgroundColor: zoomedOut ? '#d0d0d0' : 'white',
                    cursor: 'pointer',
                    margin: '2px',
                    transition: 'background-color 0.2s',
                  }}
                >
                  <strong>{unitDetails.language === "es" ? "Editar" : "Edit"}</strong>
                  <i style={{ marginLeft: '5px', fontSize: '18px', position: 'relative', top: '3px' }} className="bx bx-pencil"></i>
                </Tabs.Trigger>
              </Tabs.List>
            </Tabs.Root>
          )}
        </div>
      )}
    </div>
  )
}