import { formatName } from "./utils";
import React, { useState, useEffect, useRef } from "react";
import "react-day-picker/dist/style.css";

export const MemberDetails = ({ member, setShowDetails }) => {
  const [sessionInfo, setSessionInfo] = useState({});
  const [unitDetails, setUnitDetails] = useState({});
  const [showHouseholdPhoto, setShowHouseholdPhoto] = useState(false);
  const [isPhoneNumberHovered, setIsPhoneNumberHovered] = useState(false);
  const [isEmailHovered, setIsEmailHovered] = useState(false);
  const [isAddressHovered, setIsAddressHovered] = useState(false);
  const [copiedField, setCopiedField] = useState(null);
  const modalRef = useRef(null);
  const [noteText, setNoteText] = useState('');
  const [notes, setNotes] = useState([]);

  // Function to toggle between member and household photo
  const togglePhoto = () => {
    setShowHouseholdPhoto(!showHouseholdPhoto);
  };

  // Prepare photo sources
  let memberPhotoSrc = member.member_photo;
  let householdPhotoSrc = member.household_photo;

  // Determine which photo to display
  let currentPhotoSrc;
  let isDefaultPicture = false;
  if (memberPhotoSrc && householdPhotoSrc) {
    currentPhotoSrc = showHouseholdPhoto ? householdPhotoSrc : memberPhotoSrc;
  } else if (memberPhotoSrc) {
    currentPhotoSrc = memberPhotoSrc;
  } else if (householdPhotoSrc) {
    currentPhotoSrc = householdPhotoSrc;
  } else {
    currentPhotoSrc = "/no_profile_picture.jpeg";
    isDefaultPicture = true;
  }

  const name = formatName(member.member_name || member.recommended_name);

  function copyToClipboard(text, fieldName) {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedField(fieldName);
      setTimeout(() => setCopiedField(null), 1000);
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowDetails(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowDetails]);

  useEffect(() => {
    fetchUnitDetails();
    fetchSessionInfo();
    fetchNotes();
  }, [])

  async function fetchUnitDetails() {
    try {
      const res = await fetch("/api/get_unit_details")
      const data = await res.json();
      setUnitDetails(data);
    } catch (error) {
      console.error("Error fetching unit details", error);
    }
  }

  async function fetchSessionInfo() {
    try {
      const response = await fetch("/api/session_info");
      const sessionInfo = await response.json();
      setSessionInfo(sessionInfo);
    } catch (error) {
      console.error("Error fetching session info", error);
    }
  }

  async function fetchNotes() {
    try {
      const res = await fetch("/api/list_notes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: member.member_id || member.recommended_id }),
      });
      const data = await res.json();
      setNotes(data)
    } catch (error) {
      console.error("Error executing query", error);
    }
  }

  async function createNote(note) {
    try {
      const res = await fetch("/api/create_note", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: member.member_id || member.recommended_id, note: note }),
      });
      const data = await res.json();
      setNotes((prevNotes) => [...prevNotes, data]);
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function deleteNote(noteId) {
    try {
      await fetch("/api/delete_note", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ noteId: noteId }),
      });
      setNotes((prevNotes) => prevNotes.filter(note => note.id !== noteId));
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  const handleSave = () => {
    createNote(noteText);
    setNoteText('');
  };

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  return (
    <>
      <div className="modal-overlay"></div>
      <div className="modal" ref={modalRef}>
        <div
          className="member-card"
          style={{
            border: `5px solid #b0b0b0`,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <div style={{ cursor: "pointer", position: "absolute", top: "0px", right: "-2px", zIndex: 20000 }} onClick={() => setShowDetails(false)}>
            <i className="bx bx-x" style={{ fontSize: "22px" }}></i>
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              margin: "15px",
            }}
          >
            {memberPhotoSrc && householdPhotoSrc && !showHouseholdPhoto && (
              <button
                className="photo-toggle bx bx-chevron-right"
                onClick={togglePhoto}
                style={{
                  position: "absolute",
                  right: "-25px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  border: "none",
                  background: "none",
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              />
            )}
            <div
              style={{
                height: "380px",
                width: "380px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={currentPhotoSrc}
                alt={name}
                className="member-photo"
                style={isDefaultPicture ? { height: "100px", width: "100px" } : {}}
              />
            </div>
            {memberPhotoSrc && householdPhotoSrc && showHouseholdPhoto && (
              <button
                className="photo-toggle bx bx-chevron-left"
                onClick={togglePhoto}
                style={{
                  position: "absolute",
                  left: "-25px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  border: "none",
                  background: "none",
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
            marginLeft: "10px",
          }}>
            <i className={member.gender === 'F' ? "bx bx-female" : "bx bx-male"} style={{ fontSize: "28px" }}></i>
            <div style={{ fontSize: "18px" }}>
              <strong>{name}</strong>
            </div>
          </div>
          {(member.phone_number || member.email || member.address_line_1) &&
            <hr style={{ width: "95%", border: "1px solid #ccc" }} />
          }
          {member.phone_number && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                borderRadius: "4px",
                padding: "4px",
                marginLeft: "10px",
              }}
            >
              <i className="bx bxs-phone" style={{ fontSize: "22px" }}></i>
              <div
                onClick={() => copyToClipboard(member.phone_number, "phone_number")}
                style={{
                  cursor: "pointer",
                  backgroundColor: isPhoneNumberHovered && copiedField !== "phone_number" ? "#f0f0f0" : "transparent", 
                  fontStyle: copiedField === "phone_number" ? "italic" : "normal"
                }}
                onMouseEnter={() => setIsPhoneNumberHovered(true)} 
                onMouseLeave={() => setIsPhoneNumberHovered(false)}
              >
                {copiedField === "phone_number" ? (unitDetails.language === "es" ? "¡Copiado!" : "Copied!") : member.phone_number}
              </div>
            </div>
          )}
          {member.email && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                borderRadius: "4px",
                padding: "4px",
                marginLeft: "10px",
              }}
            >
              <i className="bx bxs-envelope" style={{ fontSize: "22px" }}></i>
              <div
                onClick={() => copyToClipboard(member.email, "email")}
                style={{
                  cursor: "pointer",
                  backgroundColor: isEmailHovered && copiedField !== "email" ? "#f0f0f0" : "transparent", 
                  fontStyle: copiedField === "email" ? "italic" : "normal"
                }}
                onMouseEnter={() => setIsEmailHovered(true)} 
                onMouseLeave={() => setIsEmailHovered(false)}
              >
                {copiedField === "email" ? (unitDetails.language === "es" ? "¡Copiado!" : "Copied!") : member.email}
              </div>
            </div>
          )}
          {member.address_line_1 && (
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "8px",
                borderRadius: "4px",
                padding: "4px",
                marginLeft: "10px",
              }}
            >
              <i className="bx bxs-map" style={{ fontSize: "22px" }}></i>
              <div
                onClick={() => copyToClipboard([member.address_line_1, member.address_line_2, member.address_line_3].filter(Boolean).join(", "), "address")}
                style={{
                  cursor: "pointer",
                  backgroundColor: isAddressHovered && copiedField !== "address" ? "#f0f0f0" : "transparent", 
                  fontStyle: copiedField === "address" ? "italic" : "normal"
                }}
                onMouseEnter={() => setIsAddressHovered(true)} 
                onMouseLeave={() => setIsAddressHovered(false)}
              >
                {copiedField === "address" ? (unitDetails.language === "es" ? "¡Copiado!" : "Copied!") : [member.address_line_1, member.address_line_2, member.address_line_3].filter(Boolean).join(", ")}
              </div>
            </div>
          )}
          {sessionInfo.canAccessNotes && (
            <>
              <hr style={{ width: "95%", border: "1px solid #ccc" }} />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "8px",
                  borderRadius: "4px",
                  padding: "4px",
                  marginLeft: "10px",
                }}
              >
                <i className="bx bxs-note" style={{ fontSize: "22px" }}></i>
                <span>{unitDetails.language === "es" ? "Notas" : "Notes"} {notes.length > 0 ? `(${notes.length})` : ""}</span>
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}>
                <textarea
                  value={noteText}
                  onChange={(e) => setNoteText(e.target.value)}
                  placeholder={unitDetails.language === "es" ? "Escribir una nota..." : "Write a note..."}
                  rows="2"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    width: "90%",
                    fontFamily: "inherit",
                    fontSize: "16px",
                    resize: "none",
                    marginBottom: "2px"
                  }}
                />
                <button onClick={handleSave} className="cta" disabled={noteText.length === 0}>
                  {unitDetails.language === "es" ? "Guardar" : "Save"}
                </button>
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}>
                {notes.map((note, index) => (
                  <div key={index} className="note-item">
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                      <div>{note.note}</div>
                      <small style={{ fontStyle: 'italic' }}>
                        {note.author} &#8212; {new Date(note.created_date).toLocaleString(undefined, options)}
                      </small>
                    </div>
                    {note.is_author && (
                      <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                        <i 
                          className="bx bx-trash delete-icon" 
                          onClick={(e) => deleteNote(note.id)}
                        ></i>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
