import React from 'react';

export const Logo = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="34px"
      height="32px"
      viewBox="0 0 484.000000 515.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,515.000000) scale(0.100000,-0.100000)"
        fill="#006184"
        stroke="none"
      >
        <path d="M3155 5121 c-149 -25 -251 -76 -357 -183 -58 -58 -86 -97 -119 -162
          -23 -46 -39 -91 -36 -99 3 -8 19 -18 36 -22 47 -10 144 -79 184 -131 20 -26
          47 -72 59 -103 l23 -56 -3 -576 -2 -577 37 -5 c21 -3 189 -5 374 -6 344 -2
          555 -7 644 -17 116 -13 249 -114 302 -231 20 -43 27 -78 31 -150 3 -51 8 -93
          13 -93 4 0 28 9 53 20 122 52 257 178 319 298 60 116 72 170 72 322 0 117 -3
          144 -24 200 -51 141 -122 241 -233 327 -143 110 -264 143 -521 143 l-147 0 6
          278 c5 235 4 287 -10 345 -43 181 -183 350 -355 427 -98 44 -249 66 -346 51z"/>
        <path d="M1525 5095 c-213 -58 -378 -214 -446 -422 -20 -61 -23 -94 -27 -321
          l-4 -253 -187 7 c-257 9 -382 -16 -517 -105 -313 -204 -403 -602 -208 -922 72
          -119 213 -232 347 -278 l57 -20 1 22 c0 12 2 31 4 42 2 11 7 40 10 65 23 154
          178 300 337 318 35 4 283 4 552 -1 389 -6 491 -5 498 5 4 7 8 248 8 535 0 301
          5 544 10 574 12 64 51 150 86 192 43 51 121 105 180 123 30 10 54 22 54 27 0
          6 -16 44 -36 86 -73 151 -238 282 -409 326 -73 19 -241 19 -310 0z"/>
        <path d="M480 2482 c-148 -55 -282 -177 -355 -325 -59 -120 -79 -212 -71 -337
          15 -257 163 -473 398 -579 100 -46 195 -61 378 -61 l160 0 0 -282 c0 -269 1
          -287 24 -360 53 -173 171 -311 328 -387 100 -47 161 -61 273 -61 115 0 175 14
          281 66 185 91 308 260 343 475 16 95 14 119 -6 119 -34 0 -138 58 -184 101
          -56 54 -92 115 -114 193 -16 54 -17 104 -10 541 l7 482 -347 6 c-192 4 -423 7
          -514 7 -224 0 -301 22 -401 116 -71 67 -130 190 -130 277 0 31 0 31 -60 9z"/>
        <path d="M4315 2338 c-22 -102 -114 -210 -223 -261 l-67 -32 -546 4 -546 3 -6
          -228 c-4 -125 -7 -340 -7 -478 0 -181 -4 -265 -14 -302 -44 -165 -178 -284
          -341 -301 l-70 -8 3 -75 c11 -266 174 -487 424 -577 64 -23 89 -26 198 -27
          111 0 134 3 205 28 181 63 325 201 389 375 35 96 37 113 36 373 l0 238 207 0
          c276 0 378 24 518 120 94 65 201 198 241 299 64 165 57 378 -19 544 -52 114
          -170 238 -285 299 -80 43 -89 44 -97 6z"/>
      </g>
    </svg>
  );
};
