import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";
import { formatName } from "./utils";
import React, { useState } from "react";
import "react-day-picker/dist/style.css";
import { format } from "date-fns";
import { MemberDetails } from "./MemberDetails";

export const MemberWithCalling = ({ member, zoomedOut, unitDetails, sessionInfo, onRelease, onUpdateSetApart, defaultBorderColor = false }) => {
  const [showHouseholdPhoto, setShowHouseholdPhoto] = useState(false);
  const [isPendingRelease, setIsPendingRelease] = useState(member.is_pending_release);
  const [showDetails, setShowDetails] = useState(false);

  // Function to toggle between member and household photo
  const togglePhoto = () => {
    setShowHouseholdPhoto(!showHouseholdPhoto);
  };

  // Prepare photo sources
  let memberPhotoSrc = member.member_photo;
  let householdPhotoSrc = member.household_photo;

  // Determine which photo to display
  let currentPhotoSrc;
  let isDefaultPicture = false;
  if (memberPhotoSrc && householdPhotoSrc) {
    currentPhotoSrc = showHouseholdPhoto ? householdPhotoSrc : memberPhotoSrc;
  } else if (memberPhotoSrc) {
    currentPhotoSrc = memberPhotoSrc;
  } else if (householdPhotoSrc) {
    currentPhotoSrc = householdPhotoSrc;
  } else {
    currentPhotoSrc = "/no_profile_picture.jpeg";
    isDefaultPicture = true;
  }

  const name = formatName(member.member_name)
  const sustainedDate = new Date(member.sustained_date);
  const monthsSinceSustained = member.months_since_sustained;

  let borderColor = "#b0b0b0";
  if (!defaultBorderColor) {
    if (isPendingRelease && (sessionInfo.accessLevel === "BISHOPRIC")) {
      borderColor = "#ea4335"
    } else if (monthsSinceSustained >= unitDetails.longer_tenure_months) {
      borderColor = "#a33b7d";
    } else if (monthsSinceSustained >= unitDetails.shorter_tenure_months) {
      borderColor = "#d988bb";
    }
  }

  const handleReleaseConfirm = () => {
    setIsPendingRelease(!isPendingRelease)
    onRelease(member.calling_id, !isPendingRelease);
  };

  if (zoomedOut) {
    return (
      <>
        <div
          className="member-card"
          style={{
            border: `3px solid ${borderColor}`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "125px",
              width: "125px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            <img
              src={currentPhotoSrc}
              alt={name}
              className="member-photo"
              style={isDefaultPicture ? { height: "75px", width: "75px" } : {}}
            />
          </div>
          <div
            style={{
              fontSize: "15px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "150px",
              cursor: "pointer"
            }}
            onClick={() => setShowDetails(true)}
          >
            <strong>{name}</strong>
          </div>
          <div
            style={{
              fontSize: "13px",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              fontStyle: "italic",
              // whiteSpace: "nowrap",
              // overflow: "hidden",
              // textOverflow: "ellipsis",
              width: "150px",
            }}
          >
            {member.position}
          </div>
        </div>
        {showDetails && <MemberDetails member={member} setShowDetails={setShowDetails} />}
      </>
    );
  }

  return (
    <>
      <div
        className="member-card"
        style={{
          border: `5px solid ${borderColor}`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <DropdownMenu>
          <DropdownMenuTrigger style={{cursor: "pointer", position: "absolute", top: "0px", right: "-5px", zIndex: 1000}} asChild>
            <i className="bx bx-dots-vertical-rounded" style={{ fontSize: "24px" }}></i>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="dropdown-content"
            align="end"
            side="bottom"      
            style={{zIndex: 1000}}
          >
            <DropdownMenuRadioGroup>
              {!isPendingRelease && (<DropdownMenuRadioItem
                className='dropdown-item'
                onClick={handleReleaseConfirm}
                style={{ fontSize: "18px" }}
              >
                <i className="bx bx-user-minus" style={{ marginRight: '5px', fontSize: "18px" }}></i>
                {unitDetails.language === "es" ? "Despedida del llamamiento" : "Release from calling"}
              </DropdownMenuRadioItem>)}
              {isPendingRelease && (<DropdownMenuRadioItem
                className='dropdown-item'
                onClick={handleReleaseConfirm}
                style={{ fontSize: "18px" }}
              >
                <i className="bx bx-undo" style={{ marginRight: '5px', fontSize: "16px" }}></i>
                {unitDetails.language === "es" ? "Eliminar estado de despedida" : "Clear released status"}
              </DropdownMenuRadioItem>)}
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <div
          style={{
            position: "relative",
            width: "240px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {memberPhotoSrc && householdPhotoSrc && !showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-right"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                right: "-15px",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
          <div
            style={{
              height: "210px",
              width: "210px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            <img
              src={currentPhotoSrc}
              alt={name}
              className="member-photo"
              style={isDefaultPicture ? { height: "100px", width: "100px" } : {}}
            />
          </div>
          {memberPhotoSrc && householdPhotoSrc && showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-left"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                left: "-15px",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
        </div>
        <div
          style={{
            fontSize: "18px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "240px",
            cursor: "pointer",
          }}
          onClick={() => setShowDetails(true)}
        >
          <strong>{name}</strong>
        </div>
        <div
          style={{
            fontSize: "16px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "240px",
            fontStyle: "italic",
            marginBottom: "8px",
          }}
        >
          {member.position}
        </div>
        <div style={{ fontSize: "15px", display: "flex", gap: "8px" }}>
          <div>
            <div>
              <strong>{unitDetails.language === "es" ? "Sostenido:" : "Sustained:"}</strong>
            </div>
            <div>{format(sustainedDate, "MM/dd/yy")}</div>
          </div>
          <div
            style={{
              borderRight: "1px solid #261232",
              height: "80%",
              margin: "0 10px",
            }}
          ></div>
          <div>
            <div>
              <strong>{unitDetails.language === "es" ? "Apartado:" : "Set apart:"}</strong>
            </div>
            <div>
              {member.is_set_apart ? (
                <i
                  className="bx bx-check"
                  style={{ fontSize: "22px" }}
                ></i>
              ) : (
                <i
                  className={member.is_pending_set_apart ? "bx bx-checkbox-checked" : "bx bx-checkbox"}
                  style={{ 
                    fontSize: "24px", 
                    cursor: "pointer" 
                  }}
                  onClick={() => onUpdateSetApart(member.calling_id, !member.is_pending_set_apart)}
                ></i>
                )}
            </div>
          </div>
        </div>
      </div>
      {showDetails && <MemberDetails member={member} setShowDetails={setShowDetails} />}
    </>
  );
};