import React, { useState } from "react";
import Select from "react-select";

export const AddMinisteringCard = ({
  onAdd,
  companionshipId,
  assignedIds,
  unitDetails,
  membersList,
}) => {
  const [recommended, setRecommended] = useState(null);
  const [isAdding, setIsAdding] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdd(companionshipId, recommended.value);
    setIsAdding(false);
  };

  const unassignedOptions = membersList
  .filter((member) => !assignedIds.includes(member.value))
  .sort((a, b) => a.label.localeCompare(b.label));

  const assignedOptions = []

  // // Separate and sort members into two groups: unassigned and assigned
  // const groupedOptions = unassignedOptions.length ? [
  //   {
  //     label: unitDetails.language === "es" ? "No asignado" : "Unassigned",
  //     options: unassignedOptions
  //   },
  //   // {
  //   //   label: unitDetails.language === "es" ? "Asignado" : "Assigned",
  //   //   options: assignedOptions
  //   // },
  // ] : membersList;

  const groupedOptions = membersList.filter((member) => !assignedIds.includes(member.value) && member.gender === "F").sort((a, b) => a.label.localeCompare(b.label));

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "16px",
      width: "100%",
      textAlign: "left",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "16px",
      textAlign: "left",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "16px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "16px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "16px",
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid #b0b0b0",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      margin: "0",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "0",
      paddingBottom: "0",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
  };

  if (!isAdding) {
    return (
      <div
        className="add-card" 
        style={{ cursor: "pointer" }}
        onClick={() => setIsAdding(true)}>
          <div
            className="bx bx-plus-circle"
            style={{ fontSize: "24px" }}
          ></div>
      </div>
    );
  }

  return (
    <div className="add-card">
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}
      >
        <Select
          value={recommended}
          onChange={(option) => setRecommended(option)}
          options={groupedOptions}
          placeholder={unitDetails.language === "es" ? "Recomendar" : "Recommend"}
          styles={selectStyles}
        />
        <div>
          <button
            className="cta"
            disabled={recommended === null}
            style={{
              cursor: recommended === null ? "default" : "pointer",
            }}
          >
            {unitDetails.language === "es" ? "Guarder" : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
};
