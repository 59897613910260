import React from "react";
import { MinisteringCard } from "./MinisteringCard";
import { AddMinisteringCard } from "./AddMinisteringCard";

export const MinisteringCompanionship = ({ unitDetails, membersList, companionshipId, type, allCompanions, allAssignments, companions, assignments, createMinisteringAssignment, createMinisteringCompanion, updateMinisteringAssignment, updateMinisteringCompanion, deleteMinisteringAssignment, deleteMinisteringCompanion }) => {
  if (type === "RS") {
    return (
      <div className="sub-organization-section">
        <div style={{ display: "flex", gap: "20px", alignItems: "center", padding: "10px" }}>
          <div style={{ display: "flex" }}>
            {companions.map((companion, index) => (
              <MinisteringCard
                key={index}
                member={companion}
                updateMinisteringAssignment={updateMinisteringAssignment}
                updateMinisteringCompanion={updateMinisteringCompanion}
                deleteMinisteringAssignment={deleteMinisteringAssignment}
                deleteMinisteringCompanion={deleteMinisteringCompanion}
                unitDetails={unitDetails}
              />
            ))}
            {companions.length < 3 &&
              <AddMinisteringCard
                companionshipId={companionshipId}
                assignedIds={allCompanions.filter(companion => !companion.is_pending_unassignment).map(companion => companion.companion_id)}
                onAdd={createMinisteringCompanion}
                unitDetails={unitDetails}
                membersList={membersList}
              />
            }
          </div>
          <i className="bx bxs-right-arrow-alt" style={{ fontSize: "24px", color: "black" }}></i>
          <div style={{ display: "flex" }}>
            {assignments.map((assignment, index) => (
              <MinisteringCard
                key={index}
                member={assignment}
                updateMinisteringAssignment={updateMinisteringAssignment}
                updateMinisteringCompanion={updateMinisteringCompanion}
                deleteMinisteringAssignment={deleteMinisteringAssignment}
                deleteMinisteringCompanion={deleteMinisteringCompanion}
                unitDetails={unitDetails}
              />
            ))}
            {assignments.length < 3 &&
              <AddMinisteringCard
                companionshipId={companionshipId}
                assignedIds={allAssignments.filter(assignment => !assignment.is_pending_unassignment).map(assignment => assignment.assignment_id)}
                onAdd={createMinisteringAssignment}
                unitDetails={unitDetails}
                membersList={membersList}
              />
            }
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="sub-organization-section">
        <div style={{ display: "flex", gap: "20px", alignItems: "center", padding: "10px" }}>
          <div style={{ display: "flex" }}>
            {companions.map((companion, index) => (
              <MinisteringCard
                key={index}
                member={companion}
                updateMinisteringAssignment={updateMinisteringAssignment}
                updateMinisteringCompanion={updateMinisteringCompanion}
                deleteMinisteringAssignment={deleteMinisteringAssignment}
                deleteMinisteringCompanion={deleteMinisteringCompanion}
                unitDetails={unitDetails}
              />
            ))}
          </div>
          <i className="bx bxs-right-arrow-alt" style={{ fontSize: "24px", color: "black" }}></i>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
              {assignments.filter(row => row.gender === 'M').map((assignment, index) => (
                <MinisteringCard
                  key={index}
                  member={assignment}
                  updateMinisteringAssignment={updateMinisteringAssignment}
                  updateMinisteringCompanion={updateMinisteringCompanion}
                  deleteMinisteringAssignment={deleteMinisteringAssignment}
                  deleteMinisteringCompanion={deleteMinisteringCompanion}
                  unitDetails={unitDetails}
                />
              ))}
            </div>
            <div style={{ display: "flex" }}>
              {assignments.filter(row => row.gender === 'F').map((assignment, index) => (
                <MinisteringCard
                  key={index}
                  member={assignment}
                  updateMinisteringAssignment={updateMinisteringAssignment}
                  updateMinisteringCompanion={updateMinisteringCompanion}
                  deleteMinisteringAssignment={deleteMinisteringAssignment}
                  deleteMinisteringCompanion={deleteMinisteringCompanion}
                  unitDetails={unitDetails}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    ); 
  }
};
